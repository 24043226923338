'use strict';

// This is a main JavaScript file that will ll be compiled into /javascripts/site.js
//
// Any JavaScript file in your site folder can be referenced here by using import or require statements.
// Additionally, you can import modules installed via your package.json file.
//
// For example:
// import './fileName'
//
// To learn more, visit https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import

$(function () {
    /// navigation ///
    var $breakpoint = 992;
    var $trigger = $('#menu_trigger');
    var $menu = $('#menu');
    var $wrapper = $('#page_wrapper');
    var $menuHeight = 0;
    var $submenu = $('#sub_menu');
    var $dropdown = $('#menu_dropdown');
    var $triggerRight = 5;

    var courseUrl = window.location.host == 'justathought.co.nz' ? 'https://course.justathought.co.nz' : 'https://course.justathought.co.nz';
    var redirectUrl = window.location.host == 'justathought.co.nz' ? 'https://www.justathought.co.nz' : 'https://www.justathought.co.nz';
    var redirects = new Map([
    // ['/login' , '/users/login'],
    ['/register', '/users/patientRegistration?site=' + redirectUrl], ['/register-clinician', '/users/clinicianRegistration?site=' + redirectUrl + '/clinicians']]);

    // -----------
    // Debug mode
    // If debug is set to true in json, redirect all traffic to /maintenance
    // Add ?debug to url to locally disable maintenance redirect
    // -----------

    /* Disabled: breaks on safari 1
    var urlParams = new URLSearchParams(window.location.search);
    var d = { 'debug': urlParams.get('debug') };
    var debugMode = (d.debug !== null )? true : false;
     $.getJSON("maintenance.json", function(json) {
      if(json.maintenance && !debugMode) {
        window.location = '/maintenance';
      }
      // console.log(json.maintenance, 'debugMode', debugMode);
    });
     // Add '?debug' to all links to keep it in debug mode
    if(debugMode){
      $("a").attr('href', function(i, h) {
       return h + "?debug";
      });
    }
    */

    // -----------
    // Redirects to course site
    // -----------
    /*
        function setRedirects(){
          const currentPathname = window.location.pathname;
          let needToRedirect = false;

          // check if pathname is in the redirectds array, then redirect the page
          for (let [pathname, redirectPathname] of redirects) {
            if(currentPathname === pathname){
              const redirectUrl = new URL(redirects.get(currentPathname), courseUrl);
              window.location = redirectUrl.href;
            }
          }
        }

        setRedirects();
    */
    if ($(window).width() < $breakpoint && $(window).width() >= 768) {
        $triggerRight = ($(window).width() - 750) / 2 + 5;
    } else {
        $triggerRight = 5;
    }

    $trigger.css('right', $triggerRight);

    function openMenu() {
        $trigger.addClass('is-open').removeClass('is-closed');
        $trigger.animate({ right: 260 }, 100);
        $menu.show().delay(200).animate({ right: 0 }, 400);
        $menuHeight = $menu.outerHeight();
        $wrapper.addClass('hide-overflow').height($menuHeight);
    }

    function closeMenu() {
        $trigger.removeClass('is-open').addClass('is-closed');
        $trigger.removeAttr('style');
        $trigger.animate({ right: $triggerRight }, 100);
        $menu.animate({ right: -260 }, 200).delay(200).hide(100);
        $wrapper.removeClass('hide-overflow').height('auto');
    }

    //Click or Enter key or Space key click for use with a 'click keydown' event listener
    function accessebilityClick(event){
        if(event.type === 'click'){
            return true; //if the event was a click return true, all clicks should trigger
        }else if(event.type === 'keypress'){
            var code = event.charCode || event.keyCode;
            if((code === 32) || (code === 13)){
                return true; //if the event was a keydown AND the key pressed was space or enter return true
            }
        }else{
            return false; //if anything but a click or enter/space keydown then return false, no action
        }
    }


    //SUBMENU KEYBOARD NAVIGATION IE11
    //Toggle sub menu open and closed when using the tab key
    //Necessary for IE11 to work as it does not have :focus-within css class
    $('#sub_menu').on('focusin', function(){
      $('#sub_menu > ul').addClass("open-sub-menu");
    });

    $('#sub_menu').on('focusout', function(){
      $('#sub_menu > ul').removeClass("open-sub-menu");
    });

    //hide bottom border on 'Our Courses' text when the submenu is being hovered.
    $('#sub_menu').hover( function(){
      $('#sub_menu > a').css("border-bottom", "none");
    }, function(){
      //Check if the current page is the courses page or one of the course pages
      //Remove the border if it isn't
      var pageWrapper = document.getElementById("page_wrapper");
      if(pageWrapper.classList.contains("course-page") || pageWrapper.classList.contains("course")){
        if(window.innerWidth > $breakpoint){
          $('#sub_menu > a').css("border-bottom", "3px solid #606eb0");
        }
      }
    });

    //-------------------------------------------------------

    $trigger.on('click keypress', function () {
      if(accessebilityClick(event) === true){
        // only expand navigation if less than breakpoint
        if ($(window).width() < $breakpoint) {

            // if it is not open already
            if (!$(this).hasClass('is-open')) {
                openMenu();
            } else {
                closeMenu();
            }

            // close with click or touch outside
            $(document).on('click touchend', function () {
                if (!$(event.target).closest('nav').length) {
                    if ($('#menu_trigger').hasClass('is-open')) {
                        closeMenu();
                    }
                }
            });
        }
      }
    }); // end click


    //DROPDOWN NAV

    var delayedClose = void 0; //keep track of menu timeout
    //the div containing course links
    var $menuDrop = $('#sub_menu > ul');
    //on either links div hover or anchor link hover
    $menuDrop.hover(function () {
        clearTimeout(delayedClose); //the last timeout is now irrelevant
        // if it is not open already
        if (!$submenu.hasClass('open')) {
            $submenu.addClass('open');
        }
    }, function () {
        //this is onmouseout
        //set dropdown to close in 0.4 seconds (allows time for user error)
        delayedClose = setTimeout(function () {
            $submenu.removeClass('open');
        }, 400);
    });
    /// navigation dropdown ///
    $dropdown.hover(function () {
        // event.preventDefault();
        clearTimeout(delayedClose); //the last timeout is now irrelevant
        // if it is not open already
        if (!$submenu.hasClass('open')) {
            $submenu.addClass('open');
        }
    }, function () {
        //this is onmouseout
        //set dropdown to close in 0.4 seconds (allows time for user error)
        delayedClose = setTimeout(function () {
            $submenu.removeClass('open');
        }, 400);
    }); // end click


    // reset navigation if wider than breakpoint
    $(window).resize(function () {
        if ($(window).width() >= $breakpoint) {
            $menu.removeAttr('style');
            $trigger.removeClass('is-open is-closed').removeAttr('style');
            $wrapper.removeClass('hide-overflow').height('auto');
            $('#sub_menu > a').css("border-bottom", "none");
            // adjust trigger position
        } else if ($(window).width() < $breakpoint && $(window).width() >= 768) {
            $triggerRight = ($(window).width() - 750) / 2 + 5;
            $trigger.removeAttr('style');
            $trigger.css('right', $triggerRight);
        } else {
            $triggerRight = 5;
        }
    }); // end resize

    // faq accordion
    // based on a pen by @kathykato - https://codepen.io/kathykato/pen/MoZJom
    var $accordion_trigger = $('.accordion_container .accordion a');

    $accordion_trigger.on('click keypress', function (event) {
      if(accessebilityClick(event) === true){
          $(this).toggleClass('active');
          $(this).next('.content').toggleClass('active');
        }
    }); // end accordion

    // Validate fields
    // Return error messages
    function validateForm() {

        //FORM INPUTS
        var from_name = document.querySelector('#name');
        var from_email = document.querySelector('#email');
        var from_message = document.querySelector('#message');


        var error_name = document.querySelector('.error_name');
        var error_email = document.querySelector('.error_email');
        var error_message = document.querySelector('.error_message');

        //Error message container
        var error_message_box = document.getElementById("error-message");

        //Form input status icons (tick/exclamation)
        var status_icon_name = document.getElementById("nameStatusIcon");
        var status_icon_email = document.getElementById("emailStatusIcon");
        var status_icon_message = document.getElementById("messageStatusIcon");

        //Array to hold error messages as incorrect form values are found
        var error_messages = [];

        //Whether the form inputs are valid or not
        var valid = true;

        // Check name
        if (from_name.value) {
            status_icon_name.classList.remove('badValue');
            status_icon_name.classList.add("goodValue");
            from_name.setAttribute('aria-invalid', 'false');
        } else {
            //name is empty
            status_icon_name.classList.remove('goodValue');
            status_icon_name.classList.add("badValue");
            valid = false;
            //notify screenreader of error
            from_name.setAttribute('aria-invalid', 'true');
            from_name.setAttribute('aria-describedby', 'name-empty');
            //add error message to array of errors
            error_messages.push("<span id='name-empty'>* Name field is required. Please enter your name.</span>");
        }

        // Check email entered
        if (from_email.value) {
            status_icon_email.classList.remove('badValue');
            status_icon_email.classList.add("goodValue");
            from_email.setAttribute('aria-invalid', 'false');

            //check email pattern
            if (/(^\w.*@\w+\.\w)/.test(from_email.value)) {
              // error_email.classList.remove('error_show');
              status_icon_email.classList.remove('badValue');
              status_icon_email.classList.add("goodValue");
              from_email.setAttribute('aria-invalid', 'false');
            } else {
              //invalid email format
              status_icon_email.classList.remove('goodValue');
              status_icon_email.classList.add("badValue");
              //notify screenreader of error
              from_email.setAttribute('aria-invalid', 'true');
              from_email.setAttribute('aria-describedby', 'email-format');
              valid = false;
              //add error message to array of errors
              error_messages.push("<span id='email-format'>* The format of your email is invalid. Please enter a valid email address.</span>");
            }

        } else {
          //NO EMAIL ENTRY
          status_icon_email.classList.remove('goodValue');
          status_icon_email.classList.add("badValue");
          //notify screenreader of error
          from_email.setAttribute('aria-invalid', 'true');
          from_email.setAttribute('aria-describedby', 'email-empty');
          valid = false;
          //add error message to array of errors
          error_messages.push("<span id='email-empty'>* Email field is required. Please enter your email.</span>");
        }


        // Check mesage
        if (from_message.value) {
          status_icon_message.classList.remove('badValue');
          status_icon_message.classList.add("goodValue");
          from_message.setAttribute('aria-invalid', 'false');
        } else {
          //empty message
          status_icon_message.classList.remove('goodValue');
          status_icon_message.classList.add("badValue");
          //notify screenreader of error
          from_message.setAttribute('aria-invalid', 'true');
          from_message.setAttribute('aria-describedby', 'message-empty');
          valid = false;
          //add error message to array of errors
          error_messages.push("<span id='message-empty'>* Message field is required. Please enter your message.</span>");
        }


        if (valid) {
          //remove status icons from the inputs
          status_icon_name.classList.remove('goodValue');
          status_icon_name.classList.remove('badValue');
          status_icon_email.classList.remove('goodValue');
          status_icon_email.classList.remove('badValue');
          status_icon_message.classList.remove('goodValue');
          status_icon_message.classList.remove('badValue');
          //send the message
          sendMail();
          //reset screenreader attributes
          from_message.removeAttribute('aria-invalid');
          from_email.removeAttribute('aria-invalid');
          from_name.removeAttribute('aria-invalid');
          from_message.removeAttribute('aria-describedby');
          from_email.removeAttribute('aria-describedby');
          from_name.removeAttribute('aria-describedby');
          //remove error messages from page
          error_message_box.innerHTML = '';
        } else {
            //Determine which field the first error occured in so it can be focused.
            var firstError = document.querySelector('.message-form > input[aria-invalid=true]');
            if(!firstError){
              firstError = document.querySelector('.message-form > textarea[aria-invalid=true]');
            }
            //Concat all error messages together and put them into the error message container
            error_message_box.innerHTML = error_messages.join('\n');
            //focus the input that had the first error
            firstError.focus();
        }
    }



    function showThankYou() {
        var message_form = document.querySelector('.message-form');
        var thank_you = document.querySelector('.thank-you');

        var from_name = document.querySelector('#name');
        var from_email = document.querySelector('#email');
        var from_message = document.querySelector('#message');

        from_name.value = '';
        from_email.value = '';
        from_message.value = '';

        message_form.style.display = 'none';
        thank_you.style.display = 'block';
    }

    function showMessageForm() {
        var message_form = document.querySelector('.message-form');
        var thank_you = document.querySelector('.thank-you');

        message_form.style.display = 'block';
        thank_you.style.display = 'none';
    }

    function NOW() {

        var date = new Date();
        var aaaa = date.getFullYear();
        var gg = date.getDate();
        var mm = date.getMonth() + 1;

        if (gg < 10) gg = "0" + gg;

        if (mm < 10) mm = "0" + mm;

        var cur_day = aaaa + "-" + mm + "-" + gg;

        var hours = date.getHours();
        var minutes = date.getMinutes() - 1;
        var seconds = date.getSeconds();

        if (hours < 10) hours = "0" + hours;

        if (minutes < 10) minutes = "0" + minutes;

        if (seconds < 10) seconds = "0" + seconds;

        return cur_day + " " + hours + ":" + minutes + ":" + seconds;
    }

    $('#submit_contact').on('click', function () {
        validateForm();
    });
    $('#btn_back').on('click', function () {
        showMessageForm();
    });
});
